@import url(https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Spinnaker&display=swap);
:root {
  --page-width: 820px;
  --font-fancy-A: 'Jost', sans-serif;
  --font-fancy-B: 'Urbanist', sans-serif;
  --font-fancy-C: 'Spinnaker', sans-serif;

  --color-hex-rose: #C89B8E;
  --color-hsl-rose: 13, 35%, 67%;

  --color-hex-lightgrey: #9A9A9A;
  --color-hsl-lightgrey: 0, 0%, 85%;;
  --color-hex-grey: #606060;
  --color-hsl-grey: 0, 0%, 38%;
  --color-hex-darkgrey: #2f2f2f;
  --color-hsl-darkgrey: 0, 0%, 18%;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;

  /* font-family: var(--font-fancy-B); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Header Style */
header {
  /* height: 60px; */
  padding: 18px 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0 0px 5px -2px rgba(0,0,0,.5);

  display: grid;
  grid-template-areas: 'logo nav donate';
  grid-template-columns: 140px 1fr 140px;
  justify-content: center;
}
header > section {
  display: flex;
}

.header-logo {
  grid-area: logo;
}
.header-nav {
  grid-area: nav;
}
.header-donate {
  grid-area: donate;
  justify-content: flex-end;
}
@media (max-width: 639px){
  header {
    grid-template-areas: 'logo donate' 'nav nav';
    grid-template-columns: 52px 1fr;
    padding: 12px 16px;
    align-items: start;
    grid-gap: 8px;
    gap: 8px;
    /* justify-content: start; */
  }
}

/* Logo */
header .name-logo {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: left;
  margin-right: 2rem;
  /* filter: hue-rotate(50deg) saturate(11); */
}


/* Nav */
header nav {
  width: 100%;
}
@media (min-width: 640px){
  header nav a {
    margin-left: 2rem;
  }
}
@media (max-width: 639px){
  header nav {
    text-align: center;
  }
  header nav a + a {
    margin-left: 2rem;
  }
}
nav a {
  text-decoration: none;
  color: #2f2f2f;
  color: var(--color-hex-darkgrey);
  border-bottom: 1px solid hsla(0, 0%, 18%, 0);
  border-bottom: 1px solid hsla(var(--color-hsl-darkgrey), 0);
  transition: .4s linear;
}
nav a:hover,
nav .active {
  border-bottom: 1px solid hsla(0, 0%, 18%, 1);
  border-bottom: 1px solid hsla(var(--color-hsl-darkgrey), 1);
}

/* Donate */
.donate-btn {
  border-radius: 50px;
  height: 36px;
  width: 120px;
  letter-spacing: 1px;
  border: 2px solid #C89B8E;
  border: 2px solid var(--color-hex-rose);
  color: #fff!important;
  /* color: var(--color-hex-rose); */
  background: linear-gradient(
    0.125turn, 
    #2f2f2f,
    #606060,
    #2f2f2f);
  background: linear-gradient(
    0.125turn, 
    var(--color-hex-darkgrey),
    var(--color-hex-grey),
    var(--color-hex-darkgrey)
    );
  font-weight: 600;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  transition: .3s linear;
  box-shadow: 1px 2px 2px rgba(0,0,0,.125);
}
.donate-btn:hover {
  background: linear-gradient(
    0.725turn, 
    #2f2f2f,
    #606060,
    #2f2f2f);
  background: linear-gradient(
    0.725turn, 
    var(--color-hex-darkgrey),
    var(--color-hex-grey),
    var(--color-hex-darkgrey)
    );
  transition: .1s ease;
}
.donate-btn:active {
  -webkit-transform: translate(0px,1px);
          transform: translate(0px,1px);
  transition: .1s ease;
  box-shadow: 0px 1px 1px rgba(0,0,0,.25);
}
.donate-btn:visited {
  border-bottom: 2px solid #C89B8E;
  border-bottom: 2px solid var(--color-hex-rose);
}
@media (max-width: 639px){
  .donate-btn {
    /* position: absolute;
    right: 12px;
    top: 12px; */
    font-size: 14px;
    height: 22px;
    width: 84px;
  }
}

/* Footer Style */
footer {
  height: 240px;
  width: 100%;
  /* background: linear-gradient(180deg, #DAC600 0%, #DAC600 38.54%, #A57A00 78.12%, #3B2100 100%); */
  background: #C89B8E;
  background: var(--color-hex-rose);
  
}


/* Text Style */
h1 {
  font-size: 2.5rem;
  font-family: 'Jost', sans-serif;
  font-family: var(--font-fancy-A);
  font-weight: 600;
}
h2 {
  font-size: 2rem;
  font-family: 'Jost', sans-serif;
  font-family: var(--font-fancy-A);
  font-weight: 600;
}
h3 {
  font-size: 1.5rem;
  font-family: 'Jost', sans-serif;
  font-family: var(--font-fancy-A);
}
h4 {
  font-size: 1.2rem;
}
p, ul {
  font-size: 1rem;
  padding-bottom: 1rem;
  line-height: 200%;
}
* > *:last-child {
  padding-bottom: 0;
}
/* margin-block override */
h1, h2, h3, h4, h5, p, ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
}
img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  cursor: pointer;
  border-bottom: 1px solid #FFFFFF;
}
a:visited {
  color: #2f2f2f;
  color: var(--color-hex-darkgrey);
  border-bottom: 1px solid #2f2f2f;
  border-bottom: 1px solid var(--color-hex-darkgrey);
}
a:hover {
  color: #606060;
  color: var(--color-hex-grey);
  border-bottom: 1px solid #C89B8E;
  border-bottom: 1px solid var(--color-hex-rose);
}
/* Page Style */
.page-wrapper {
  min-height: calc(100dvh - 348px);
}
.page-width {
  width: 90%;
  max-width: 820px;
  max-width: var(--page-width);
  margin: 0 auto;
}


/* Component Style */
.txt-over-img-box {
  position: relative;
}
.txt-foreground,
.img-background {
  width: 100%;
  height: 100%;
}
.img-background {
  position: absolute;
  top: 0;
  left: 0;
}
.txt-foreground {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
  z-index: 2;
}
.txt-foreground>div {
  position: relative;
}
.img-background>img {
  width: 100%;
  height: 100%;
  object-fit: cover;

}
.darken {
  filter: grayscale(.66) brightness(.33);
  -webkit-filter: grayscale(.66) brightness(.33);
  -moz-filter: grayscale(.66) brightness(.33);
}


@media (min-width: 640px){ .mobile-only { display: none; } }
@media (max-width: 639px){ .desktop-only { display: none; } }

/* Grid template */
.grid {
  display: grid;
  grid-gap: 32px;
  gap: 32px;
}
.one-col {
  grid-template-columns: 1fr;
}
.two-col {
  grid-template-columns: 1fr 1fr;
}
@media (min-width: 640px){
  .grid {
    grid-template-areas: 'left right';
  }  
  .grid>.left { grid-area: left; }
  .grid>.right { grid-area: right; }
  
  .two-col,
  .desktop-two-col {
    grid-template-columns: 1fr 1fr;
  }
  .desktop-two-col.two-one {
    grid-template-columns: 2fr 1fr;
  }
}
@media (max-width: 639px){
  .grid {
    grid-gap: 1em;
    gap: 1em;
    grid-template-areas: 'top' 'bottom';
  }
  .grid.mobile-one-col>.top { grid-area: top; }
  .grid.mobile-one-col>.bottom { grid-area: bottom; }

  .mobile-two-col {
    grid-template-columns: 1fr 1fr;
  }
  .mobile-one-col {
    grid-template-columns: 1fr;
  }
}

/* Input Style */
input {
  font-size: 18px;
  height: 36px;
  margin: 5px auto;
  border-width: 1px;
  border-radius: 0px;
  cursor: text;
}

input[type=submit] {
  font-size: 18px;
  line-height: 100%;
  text-transform: uppercase;
  border-radius: 0;
  height: 39px;
  border: #181717 solid 1px;
  position: relative;
  z-index: 900;
  width: 100%;
  max-width: 300px;
  cursor: pointer;
  background-color: #000;
  color:#fff;
  transition: 1s ease;
}

input[type=submit]:hover {
  background-color: #CCCCCC;
}
button {
  border-radius: 0px;
  border: 1px solid black;;
  background: transparent;
  cursor: pointer;
}
.header {
    line-height: 100%;
    padding-bottom: 1em;
}
.header small {
font-weight: 300;
font-size: clamp(16px, .45em, 24px);
display: block;
line-height: 120%;
}
.contact-page > section,
.home-page > section {
    padding: 4rem 0;
    position: relative;
}
.home-page > section:first-of-type {
    padding: 2rem 0 4rem;
}
.home-page .info-section {
    text-align: center;
}


.home-about .headshot {
    opacity: 1;
    margin-top: 0;
    transition: 1s ease;
}
.home-about.hide-item .headshot {
    opacity: 0;
    margin-top: 6px;
}


@media (min-width: 640px ){
    .home-page .headshot {
        font-size: 32px;
        width: calc(50% - .5em);
        max-width: 240px;
        float: right;
        padding: 0 0 0 1em;
    }
}
@media (max-width: 639px ){
    .home-page .headshot {
        font-size: 16px;
        width: 50%;
        float: left;
        padding: 0 1em 0 0;
    }
}

@media (max-width: 639px ){
    .home-subscribe  h2 {
        font-size: max(32px, 8vw);
    }
}
.home-subscribe .txt-over-img-box {
    position: relative;
}
.home-subscribe .txt-foreground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
}
.home-subscribe .txt-foreground>div {
    width: 100%;
    text-align: center;
}
.home-subscribe .img-background>img {
    object-position: left;
}
.home-subscribe .txt{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
@media (max-width: 639px ){
    .home-subscribe .txt{
        justify-content: center;
    }
}
.home-subscribe .right.bottom p {
    padding-bottom: 0;
}
.text-io {
    opacity: 1;
    margin-top: 0;
    transition: 1s ease;
  }
  .text-io.hide-item {
    opacity: 0;
    margin-top: 6px;
  }
form.email {
    --gap: 8px;
    --border: 1px;
    --input-padding: calc(var(--gap) + var(--border));
    --input-total-padding: calc(var(--input-padding) * 2);
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}
.email label, .email input {
    margin-left: 0;
    margin-right: 0;
}
.email textarea,
.email .input { 
    width: calc(100% - var(--input-padding)*2);
    padding-inline: var(--gap); 
}


/* Type specific form style */
.email .name-section,
.email.contact .contact-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--gap);
    gap: var(--gap);
}



.email.newsletter .contact-section {
    display: flex;
}

.email textarea {
    border: 1px solid black;
    border-radius: 0px;
    height: 120px;
    margin: 5px 0;
    font-family: Montserrat, Arial;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #473E4E;
}
