.header {
    line-height: 100%;
    padding-bottom: 1em;
}
.header small {
font-weight: 300;
font-size: clamp(16px, .45em, 24px);
display: block;
line-height: 120%;
}
.contact-page > section,
.home-page > section {
    padding: 4rem 0;
    position: relative;
}
.home-page > section:first-of-type {
    padding: 2rem 0 4rem;
}
.home-page .info-section {
    text-align: center;
}


.home-about .headshot {
    opacity: 1;
    margin-top: 0;
    transition: 1s ease;
}
.home-about.hide-item .headshot {
    opacity: 0;
    margin-top: 6px;
}


@media (min-width: 640px ){
    .home-page .headshot {
        font-size: 32px;
        width: calc(50% - .5em);
        max-width: 240px;
        float: right;
        padding: 0 0 0 1em;
    }
}
@media (max-width: 639px ){
    .home-page .headshot {
        font-size: 16px;
        width: 50%;
        float: left;
        padding: 0 1em 0 0;
    }
}

@media (max-width: 639px ){
    .home-subscribe  h2 {
        font-size: max(32px, 8vw);
    }
}
.home-subscribe .txt-over-img-box {
    position: relative;
}
.home-subscribe .txt-foreground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
}
.home-subscribe .txt-foreground>div {
    width: 100%;
    text-align: center;
}
.home-subscribe .img-background>img {
    object-position: left;
}
.home-subscribe .txt{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
@media (max-width: 639px ){
    .home-subscribe .txt{
        justify-content: center;
    }
}
.home-subscribe .right.bottom p {
    padding-bottom: 0;
}